<template>
    <div class="change-data-page">
        <div class="container-fluid">

            <h1>Edycja użytkownika</h1>
            
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
            <div v-else class="form-container">
                <form method="post" @submit.prevent="beforeSubmit" action="">
                    <inputLabel v-model="form.type" name="type" label="Typ"  />

                    <inputText v-model="form.name" name="name" label="Imię" :error="validation.firstError('form.name')" />
                    <inputText v-model="form.surname" name="surname" label="Nazwisko"  :error="validation.firstError('form.surname')"  /> 
                    
                    <inputEmail v-model="form.email" name="email" label="E-mail" :showSpinner="validation.isValidating('form.email')" 
                           :error="validation.firstError('form.email')" />
                    <inputText v-model="form.phone" name="phone" label="Telefon" :error="validation.firstError('form.phone')" />

                    <inputCheckbox v-model="form.active" name="active" label="Aktywny" :error="validation.firstError('form.active')" />

                    <dismissAlert ref="errorAlert" />

                    <div class="text-center">
                        <buttonLoader type="submit" class="btnLong" :isLoading="inProgress" label="Zmień" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

export default {
    name: "adminUserEdit",
    data() {
        return {
            userId: this.$route.params.userId,
            inProgress: false,
            isChanged: false,
            successMessage: '',
            isLoading: true,
            user: null,
            form: {
                type: null,
                name: null,
                surname: null,
                email: null,
                phone: null,
                active: false,
            }
        }
    },
    mounted(){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
        axios.get('/admin/user/get/' + this.userId).then( (responce) => {
            this.isLoading =  false;
            this.user = responce.data;
            this.form.type = this.userTypeLabel(this.user.type);
            this.form.name = this.user.name;
            this.form.surname = this.user.surname;
            this.form.email = this.user.email;
            this.form.phone = this.user.phone;
            this.form.active = this.user.active ? true : false;
        }).catch( () => {
            this.isLoading = false;
            this.$bvToast.toast(`Błąd pobierania danych`, {
                title: 'Błąd',
                autoHideDelay: 5000,
                solid: true,
                variant: 'danger',
                appendToast: true,
                toaster: 'b-toaster-top-right'
            });
        });
    },
    validators: {
        'form.name': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.surname': function (value) {
            return Validator.value(value).required().maxLength(100);
        },
        'form.phone': function (value) {
            if (value)
                return Validator.value(value).maxLength(50);
        },
        'form.email': {
            cache: 'last',
            debounce: 200,
            validator: function (value) {
                let vMessage = Validator.value(value)
                    .required()
                    .minLength(5)
                    .maxLength(255)
                    .email();
                if ( vMessage._messages.length == 0 ) {
                    vMessage = Validator.custom(function () {  
                        if (!Validator.isEmpty(value)) {
                            return this.$store.dispatch('auth/checkEmailIsFree', { email: this.form.email, id:this.userId } )
                                .catch( ( error ) =>  {
                                    return error;
                                });
                        }
                    }.bind(this) );
                }
                return vMessage;
            }
        },
    },
    methods: {
        userTypeLabel(type) {
            let label = '';
            switch( type ) {
                case USER_typeAdmin: { label = 'Administrator'; break;}
                case USER_typeDriver: { label = 'Kierowca'; break;}
                case USER_typeCustomer: { label = 'Klient'; break;}
            }
            return label;
        },
        beforeSubmit(){
            this.inProgress = true;
            this.$validate().then( this.submit );
        },
        submit(success){
            if (success) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                axios.post('/admin/user/edit', {
                    ... this.form,
                    id: this.userId
                }).then( () => {
                    this.$router.push( {name: 'AdminUserPreview',  params: { userId: this.userId }} );
                }).catch( () => {
                    this.inProgress = false;
                    this.$bvToast.toast(`Błąd zmiany danych`, {
                        title: 'Błąd',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'danger',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                });
            } else {
                this.inProgress = false;
            }
        }
    }
};
</script>